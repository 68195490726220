<template>
  <PageCard
    pageName="FI Vertical Strategy"
    :iconClass="['cuis-file-code', 'card-icon-font']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div
        :class="
          partnerSite ? ['homepage-content partner-site'] : ['homepage-content']
        "
      >
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <div class="ps-2">
                <div class="row card-row ">
                  <div class="col-9">
                    <h1 class="card-title d-inline-block pr-3 banner-title">
                      FI Vertical Strategy
                    </h1>
                    <div class="solution-icon same-col soln-img"></div>
                  </div>
                </div>
                <p class="card-text mb-4 soln-desc">
                  The Data to Connect the Dots
                </p>
              </div>
              <FeatureDetailsCard
                title="FinTech Market Reports"
                description1="Quickly assess market share or client firmographics by vendor or vendor-product."
                description2="Other vendors offering your technology may not be your true competition – if they attract different FI segments. Leverage the analysis for strategy development, competitive intel, segment analysis, white space identification or marketing ammo."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_M"
              />
              <FeatureDetailsCard
                title="FIN Digital Channels"
                description1="FIN Digital Channels focuses on retail mobile &amp; online banking delivering innovative metrics on vendor and FI performance."
                description2="The solution produces comparative vendor dashboards on market share, share accretion, churn rates, and much more.  Institution dashboards cover every U.S. institution segmented by FI type, asset size &amp; other variables."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_D"
              />
              <FeatureDetailsCard
                title="FIN Industry Reporter"
                description1="Uniquely harness industry data to inform strategy or highlight your clients’ higher performance."
                description2="Create FI peer groups, segmentation schemes and custom reports to identify the critical factors driving industry performance. Develop segmentation ranging from market size to core technologies deployed to stratify performance."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="VC_SOL_FIN_I"
              />
            </div>
          </div>
        </section>
        <UseCaseDetails></UseCaseDetails>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'
import UseCaseDetails from './UseCaseDetails.vue'

import imgVcPC from '../../../../static/img/vc-benchmarking.png'
import imgVcSE from '../../../../static/img/handshake.png'
import imgVcAP from '../../../../static/img/icon-pie-chart.png'
import imgVcMS from '../../../../static/img/icon-tactics.png'
export default {
  name: 'AboutMarketReports',
  components: {
    PageCard,
    FeatureDetailsCard,
    UseCaseDetails
  },
  data() {
    return {
      imgVcPC: imgVcPC,
      imgVcSE: imgVcSE,
      imgVcAP: imgVcAP,
      imgVcMS: imgVcMS,
      VC_SOL_FIN_M: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_M4.png'
      ],
      VC_SOL_FIN_D: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_D6.png'
      ],
      VC_SOL_FIN_I: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_I1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_I2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_I3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_I4.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Vertical Strategy'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.solution-section {
  .soln-img {
    background: url('../../../../static/img/icon-strategic.png');
    background-size: cover;
    // margin-bottom: 10px;
  }
}
</style>
